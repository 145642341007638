import { PRODUCT_ERROR, PRODUCT_LOADING, PRODUCT_SUCCESS } from "./type";

const initialState = {
  allProducts: [],
  loading: true,
  error: null,
};

export const allProducts = (state = initialState, { type, payload }) => {
  switch (type) {
    case PRODUCT_LOADING:
      return { ...state, loading: true };
    case PRODUCT_SUCCESS:
      return { ...state, loading: false, allProducts: payload };
    case PRODUCT_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
