import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import NewsCard from "../../components/NewsCard/NewsCard";
import { Language } from "../../services/lang";
import "./index.scss";
const AllNews = () => {
  const { allNews } = useSelector((state) => state.allNews);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <div className="allNewsContainer globalContainer">
      <h3>{Language("ALLNEWS")}</h3>
      <div className="allNewsProducts">
        {allNews.map((item) => (
          <NewsCard
            img={item.img}
            title={item.title}
            subtitle={item.subtitle}
            id={item.id}
            key={item.id}
          />
        ))}
      </div>
    </div>
  );
};

export default AllNews;
