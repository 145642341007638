import React from "react";
import "./index.scss";
const ScrollDown = () => {
  return (
    <div className="scrollDown">
      <div className="chevron"></div>
      <div className="chevron"></div>
      <div className="chevron"></div>
    </div>
  );
};

export default ScrollDown;
