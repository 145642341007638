import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "../../components/Cards/ProductCard/ProductCard";
import { Select } from "antd";
import "./index.scss";
import { useSelector } from "react-redux";
import { API } from "../../services/imgAPI";
const { Option } = Select;
const Category = () => {
  const { allProducts } = useSelector((state) => state.allProducts);
  const param = useParams();
  const yy = allProducts.filter((item) => item.type === param.id)
  ?.map((item) => item?.category)?.map(item=>item[0])[0];
  const [filterCategoyr, setFilterCategory] = useState(yy === undefined ? 0 : yy.id);
 
  const sortCategory = (e) => {
    setFilterCategory(e.target.id);
  };
  const sortCategorySelect = (e) => {
      setFilterCategory(e);
  };
  
  const category = allProducts
    .filter((item) => item.type === param.id)
    .map((item) => item.category);
  const data = allProducts
    ?.filter((item) => item.type === param.id)
    .map((item) => item.data);
  const filterProduct = data?.map((item) =>
    item.filter((item) => item.category_id === filterCategoyr)
  );
  const title = allProducts.filter((item) => item.type === param.id);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [filterCategoyr]);
  return (
    <>
      <div className="categoryWrapper globalContainer">
        {param.id === "meat" && (
          <>
            <div className="categoryTitles">
              <ul>
                {category.map((item) =>
                  item.map((item) => (
                    <li
                      id={item.id}
                      onClick={(e) => sortCategory(e)}
                      className={item.id === filterCategoyr && "focus"}
                      key={item.id}
                    >
                      {item.name}
                    </li>
                  ))
                )}
              </ul>
            </div>
            <div className="categorySelect">
              <Select
                defaultValue={category.map(item=>item[0])[0].name}
                style={{
                  width: 120,
                }}
                onChange={sortCategorySelect}
              >
                {category.map((item) =>
                  item.map((item2) => <Option value={item2.id}>{item2.name}</Option>)
                )}
              </Select>
            </div>
          </>
        )}
        <div className="categoryContent">
          <h3>{title[0].name}</h3>
          {param.id === "meat"
            ? filterProduct.map((item) =>
                item.map((item) => (
                  <ProductCard
                    img={item.img}
                    type={title[0].name}
                    name={item.name}
                    id={item.id}
                    key={item.id}
                  />
                ))
              )
            : data.map((item) =>
                item.map((item) => (
                  <ProductCard
                    img={item.img}
                    type={title[0].name}
                    name={item.name}
                    id={item.id}
                    key={item.id}
                  />
                ))
              )}
        </div>
      </div>
    </>
  );
};

export default Category;
