import "./Modal.scss";
import clos from "../../assets/images/close.png";
import { Modal as AntModal } from "antd";

const Modal = ({ children, visable = true, set, hidden = false }) => {
  const setting = {
    visible: visable,
    centered: true,
    closable: false,
    footer: null,
    mask: true,
  };
  return (
    <section>
      <AntModal {...setting} width={"auto"}>
        <div
          className="myModal__close"
          onClick={() => set(false)}
          hidden={hidden}
        >
          <img src={clos} alt="img" />
        </div>
        {children}
      </AntModal>
    </section>
  );
};

export default Modal;
