import { CATEGORY_ERROR, CATEGORY_LOADING, CATEGORY_SUCCESS } from "./type";

const initialState = {
  category: [],
  loading: true,
  error: null,
};

export const category = (state = initialState, { type, payload }) => {
  switch (type) {
    case CATEGORY_LOADING:
      return { ...state, loading: true };
    case CATEGORY_SUCCESS:
      return { ...state, loading: false, category: payload };
    case CATEGORY_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
