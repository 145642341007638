import React from "react";
import { Link } from "react-router-dom";
import { API } from "../../services/imgAPI";
import "./BigCardNew.scss";

function BigCardNew({ data }) {
  return (
    <div className="bigCardNew">
      {/* <Link to={`/news/info/${data?.id}`}> */}
        <div className="bigCardNew__fourCard">
          <div className="bigCardNew__fourCard__bigCardPhoto">
            <img src={API + data?.img} alt="" />
          </div>
          <div className="bigCardNew__fourCard__bigCardText">
            <h3>{data?.title}</h3>
            <p>{data?.subtitle}</p>
          </div>
        </div>
      {/* </Link> */}
    </div>
  );
}

export default BigCardNew;
