import React from "react";
import supply from "../../assets/icons/supply.svg";
import { HiPaperAirplane } from "react-icons/hi";
import { useState } from "react";
import requestApi from "../../services/API";
import { message } from "antd";
import { Language } from "../../services/lang";
import InputMask from "react-input-mask";

import "./index.scss";
const SupplyDemand = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [body, setBody] = useState("");
  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      email: email.replace(/[^0-9]/g, ""),
      body: body,
    };
    requestApi.post("/request/send.php", data).then((res) => {
      if (res.status === 200) {
        message.success("Xabar muvofiqiyatli yuborildi !");
        setName("");
        setEmail("");
        setBody("");
      }
    });
  };
  return (
    <section className="supplyDemand">
      <div className="supplyDemandContainer globalContainer">
        <div>
          <form onSubmit={onSubmit}>
            <p> {Language("DEMANTD")}</p>
            <h3> {Language("SUBMIT")}</h3>
            <p className="title"> {Language("FULLNAME")}</p>
            <input
              type="text"
              required
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <p className="title"> {Language("NUMBER")}</p>
            <InputMask
              mask="+\9\9\8\ 99 999 99 99"
              maskChar=" "
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <textarea
              cols="10"
              rows="5"
              placeholder={Language("ANDS")}
              required
              onChange={(e) => setBody(e.target.value)}
              value={body}
            ></textarea>
            <br />
            <button type="submit">
              {Language("MASSAGE")} <HiPaperAirplane />
            </button>
          </form>
        </div>
        <div>
          <img src={supply} alt="" />
        </div>
      </div>
    </section>
  );
};

export default SupplyDemand;
