import { SWIPER_ERROR, SWIPER_LOADING, SWIPER_SUCCESS } from "./type";

const initialState = {
  mainSwiper: [],
  loading: true,
  error: null,
};

export const mainSwiper = (state = initialState, { type, payload }) => {
  switch (type) {
    case SWIPER_LOADING:
      return { ...state, loading: true };
    case SWIPER_SUCCESS:
      return { ...state, loading: false, mainSwiper: payload };
    case SWIPER_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
