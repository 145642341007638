import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/icons/logo.webp.svg";
import Modal from "../../Modal/Modal";
import Kontakt from "./_component/Kontakt/Kontakt";
import { Language } from "../../../services/lang";
import LocationModal from "./_component/Kontakt/LocationModal/LocationModal";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import "./index.scss";

const BottomHeader = () => {
  const [open, setOpen] = useState(false);
  const [locat, setLocat] = useState(false);
  const [modal, setModal] = useState(false);
  const [change, setChange] = useState({
    backgroundColor: "transparent",
  });
  const location = useLocation();
  window.onscroll = function () {
    myFunction();
  };

  function myFunction() {
    if (location.pathname === "/") {
      if (document.documentElement.scrollTop > 5) {
        setChange({
          backgroundColor: "#242334",
          top: "0",
        });
      } else {
        setChange({
          backgroundColor: "transparent",
          top: "50px",
        });
      }
    }
    if (location.pathname !== "/") {
      if (document.documentElement.scrollTop > 5) {
        setChange({
          backgroundColor: "#242334",
          top: "0",
        });
      } else {
        setChange({
          backgroundColor: "#242334",
          top: "0",
        });
      }
    }
  }
  useEffect(() => {
    myFunction();
  }, [location.pathname]);
  return (
    <>
      <nav className="bottomHeaderContainer" style={change}>
        <div className="bottomHeader globalContainer">
          <Link to={`/aboutUs`}>
            <div className="bottomIcon">
              <p>{Language("ABOUT")}</p>
            </div>
          </Link>
          <div className="logotipPhoto">
            <Link to={"/"}>
              <img
                src={logo}
                alt=""
                width={change.backgroundColor === "transparent" ? 300 : 200}
              />
            </Link>
          </div>
          <div className="rightPage">
            <p onClick={() => setLocat(true)}>{Language("LOCATION")}</p>
            <p onClick={() => setOpen(true)}>{Language("CONTACT")}</p>
          </div>
        </div>
        {open && <Modal children={<Kontakt set={setOpen} />} set={setOpen} />}
        {locat && (
          <Modal children={<LocationModal set={setLocat} />} set={setLocat} />
        )}
      </nav>
      <nav className="bottomHeaderContainer2">
        <div className="bottomHeaderTitle globalContainer">
          <Link to={"/"}>
            <img src={logo} alt="" />
          </Link>
          <div className="icon" onClick={() => setModal(true)}>
            <GiHamburgerMenu />
          </div>
        </div>
        {modal && (
          <Modal
            children={
              <div
                className="bottomHeaderModal"
                style={{ width: "90vw", height: "90vh" }}
              >
                <div
                  style={{
                    fontSize: "30px",
                    position: "absolute",
                    right: "35px",
                    top: "65px",
                  }}
                  onClick={() => setModal(false)}
                >
                  <AiOutlineClose />
                </div>
                <div
                  style={{
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    top: "50%",
                    transform: "translateY(-50%)",
                    textAlign:"center"
                  }}
                >
                  <Link to={`/aboutUs`}>
                    <p
                      style={{
                        fontSize: "25px",
                        paddingBottom: "10px",
                        color: "#000",
                      }}
                      onClick={() => setModal(false)}
                    >
                      {Language("ABOUT")}
                    </p>
                  </Link>
                  <p
                    onClick={() => setLocat(true)}
                    style={{
                      fontSize: "25px",
                      paddingBottom: "10px",
                      color: "#000",
                    }}
                  >
                    {Language("LOCATION")}
                  </p>
                  <p
                    style={{
                      fontSize: "25px",
                      paddingBottom: "10px",
                      color: "#000",
                    }}
                    onClick={() => setOpen(true)}
                  >
                    {Language("CONTACT")}
                  </p>
                </div>
              </div>
            }
            hidden={true}
          />
        )}
      </nav>
    </>
  );
};

export default BottomHeader;
