import { getVideo } from "./api";
import { VIDEO_ERROR, VIDEO_LOADING, VIDEO_SUCCESS } from "./type";

export const actionVideo = () => async (dispatch) => {
  await dispatch({ type: VIDEO_LOADING });
  try {
    const { data } = await getVideo();
    await dispatch({ type: VIDEO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: VIDEO_ERROR, payload: error });
  }
};
