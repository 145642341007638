import React from "react";
import ReactPlayer from "react-player";
import "./VideoCard.scss";

function VideoCard({ video, id }) {
  return (
    <div className="videoCards">
      <div className="videoCards__videoOne">
        <ReactPlayer
          url={video}
          controls={true}
          className="video-card"
        />
      </div>
    </div>
  );
}

export default VideoCard;
