import Category from "./pages/Category/Category";
import AboutUs from "./components/AboutUS/AboutUs";
import Home from "./pages/Home/Home";
import ProductView from "./pages/ProductView/ProductView";
import NewsInfo from "./pages/Home/_components/News/_components/NewsInfo/NewsInfo";
import AllNews from "./pages/AllNews/AllNews";
import Video from "./pages/Video/Video";
import Products from "./pages/Products/Products";

const routes = [
  { path: `/`, element: <Home /> },
  { path: `/product/view/:id`, element: <ProductView /> },
  { path: `/category/:id`, element: <Category /> },
  { path: `/aboutUs`, element: <AboutUs /> },
  { path: `/news/info/:id`, element: <NewsInfo /> },
  { path: `/news/all`, element: <AllNews /> },
  { path: `/video/all`, element: <Video /> },
  { path: `/products`, element: <Products /> },
];
export default routes;
