import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import VideoCard from '../../components/VideoCard/VideoCard';
import { API } from '../../services/imgAPI';
import { Language } from '../../services/lang';
import "./index.scss"
const Video = () => {
  const { allVideo } = useSelector((state) => state.allVideo);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <div className='allVideoContainer globalContainer'>
        <h3>{Language("VIDEO")}</h3>
        <div className='allVideoTitle'>
        {allVideo.map((item) => (
            <VideoCard
              img={API + item.img}
              title={item.title}
              video={item.link}
              id={item.id}
            />
          ))}
        </div>
    </div>
  )
}

export default Video