import { combineReducers } from "redux";
import { allNumbers } from "./numbers/reducer";
import { mainSwiper } from "./mainSwiper/reducer";
import { allNews } from "./news/reducer";
import { allProducts } from "./products/reducer";
import { category } from "./category/reducer";
import { allVideo } from "./video/reducer";
const rootReducer = combineReducers({
  allNumbers,
  mainSwiper,
  allNews,
  allProducts,
  category,
  allVideo,
});

export default rootReducer;
