import { getCategory } from "./api";
import { CATEGORY_ERROR, CATEGORY_LOADING, CATEGORY_SUCCESS } from "./type";

export const actionCategory = () => async (dispatch) => {
  await dispatch({ type: CATEGORY_LOADING });
  try {
    const { data } = await getCategory();
    await dispatch({ type: CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CATEGORY_ERROR, payload: error });
  }
};
