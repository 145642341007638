import React from "react";
import ProductCard from "../../../../components/Cards/ProductCard/ProductCard";
import { useState } from "react";
import { useEffect } from "react";
import ScrollDown from "../../../../components/ScrollDown/ScrollDown";
import { useSelector } from "react-redux";
import { API } from "../../../../services/imgAPI";
import { Language } from "../../../../services/lang";
import "./AllProducts.scss";

function AllProducts() {
  const { allProducts } = useSelector((state) => state.allProducts);
  const [count, setCount] = useState(12);
  const allData = [];
  allProducts.map((item) => item.data.map((item) => allData.push(item)));

  const func = (param) => {
    const data = allProducts.filter((item) => item.id === param);
    return data[0].name;
  };
  useEffect(() => {
  }, [count]);
  return (
    <div className="allProducts globalContainer" id="allProduct">
      <h3>
      {Language("ALLPRODUCT")}
      </h3>
      <div className="allProductContainer">
        {allData
          .filter((_, index) => index < count)
          .map((item) => (
            <ProductCard
              img={item?.img}
              name={item.name}
              type={func(item.type_id)}
              id={item.id}
              key={item.id}
            />
          ))}
      </div>

      <div className="viewMore">
        <ScrollDown />
        <p onClick={() => setCount(count + 8)}>{Language("SEEMORE")}</p>
      </div>
    </div>
  );
}

export default AllProducts;