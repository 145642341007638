import React from "react";
import "./LocationModal.scss";
import { ImLocation2 } from "react-icons/im";
import { Language } from "../../../../../../services/lang";
function LocationModal() {
  return (
    <div className="locationModal">
      <div className="locationModal__locationHeader">
        <h3> {Language("ALLADRESS")}</h3>
      </div>
      <div className="locationModal__locationInfo">
        <p><ImLocation2 /> {Language("LOCAL")} </p>
        <p><ImLocation2 /> {Language("LOCALADRES")} </p>
        <p><ImLocation2 /> {Language("LOCALANDIJAN")} </p>
      
      </div>
    </div>
  );
}

export default LocationModal;
