export const mask = (x) => {
    if(x === null){
      return x
    }
    let num = String(x);
    let mask = `+${num.substring(0, 3)}(${num.substring(3, 5)}) ${num.substring(
      5,
      8
    )}-${num.substring(8, 10)}-${num.substring(10, 12)}`;
    return mask;
  };
  