import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/autoplay";
import "./index.scss";

const MainSwiper = () => {
  const { mainSwiper } = useSelector((state) => state.mainSwiper);
  return (
    <div className="mainSwaper">
      <Swiper
        loop="false"
        className="mySwiper"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {mainSwiper.map((item) => (
          <SwiperSlide key={item.id}>
            <div
              className="my-swiper"
              style={{ backgroundImage: `url(${'https://qazimarkazi.uz/' + item.img})` }}
            >
              <h2>{item.title}</h2>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MainSwiper;
