import React from "react";
import ProductCard from "../../../components/Cards/ProductCard/ProductCard";
import "swiper/css";
import "./index.scss";
import { Language } from "../../../services/lang";
import { useSelector } from "react-redux";
import { API } from "../../../services/imgAPI";
const ProductsSection = () => {
  const { allProducts } = useSelector((state) => state.allProducts);
  const allData = [];
  allProducts.map((item) => item.data.map((item) => allData.push(item)));
    const func = (param) => {
    const data = allProducts.filter((item) => item.id === param);
    return data[0].name;
  };
  return (
    <div className="productsSection globalContainer">
      <h2> {Language("TOP")}</h2>
      <div className="productBox">
        {allData.filter(item=>item.favorite === true).map((item) => (
          <ProductCard
          img={item.img}
          name={item.name}
          type={func(item.type_id)}
          id={item.id}
          key={item.id}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductsSection;
