import React, { useEffect } from "react";
import "./AboutUs.scss";
import home from "../../assets/videos/home.mp4";
import { Language } from "../../services/lang";
function AboutUs() {
  const language = localStorage.getItem("language");
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <div className="about globalContainer">
      <div className="about__aboutMe">
        <div className="remoteVideo">
          <video width="100%" autoPlay loop muted>
            <source src={home} type="video/mp4" />
          </video>
        </div>
        <h2>{Language("INFOKOMPAN")}</h2>
        <p>{Language("ACTIVE")}</p>
        <h2>{Language("ZADACHA")}</h2>
        <p> {Language("INFOTEXT")}</p>
        <h2>{Language("LEGAL")}</h2>
        <p> {Language("LEGALADRES")}</p>
        <p> {Language("STRIT")}</p>
        <p> {Language("INN")}</p>
        <p> {Language("OKED")}</p>
        <p> {Language("BANK")}</p>
        <p> {Language("MFO")}</p>
        <p> {Language("HR")}</p>
        <p> {Language("STATE")}</p>
        <h2> {Language("PLESCE")}</h2>
        <p> {Language("LOCAL")}</p>
        <p> {Language("LOCALADRES")}</p>
        <p> {Language("LOCALANDIJAN")}
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
