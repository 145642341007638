import React, { useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { useSelector } from "react-redux/es/exports";
import { Language } from "../../../../../services/lang";
import { mask } from "../../../../../services/mask";
import "./Kontakt.scss";

function Kontakt() {
  const { allNumbers } = useSelector((state) => state.allNumbers);
  return (
    <div className="kontakt">
      <div className="kontakt__kontaktModal">
        <h3>{Language("PHONENUMBER")}</h3>
      </div>
      <div className="kontakt__numberKontakt">
        {allNumbers?.map((item) => (
          <p key={item.id}>
            <FaPhoneAlt />
            {mask(item.phoneNumber)}
          </p>
        ))}
      </div>
    </div>
  );
}

export default Kontakt;
