import React from "react";
import { useEffect } from "react";
import MainSwiper from "../../components/MainSwiper/MainSwiper";
import Sertification from "../../components/Sertification/Sertification";
import SupplyDemand from "../../components/SupplyDemand/SupplyDemand";
import "./index.scss";
import AllProducts from "./_components/AllProducts/AllProducts";
import Menyu from "./_components/Menyu/Menyu";
import News from "./_components/News/News";
import Partner from "./_components/Partner/Partner";
import ProductsSection from "./_components/ProductsSection";
import Videos from "./_components/Videos/Videos";
import WhyUs from "./_components/WhyUs/WhyUs";
const Home = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <div className="homeContainer">
      <MainSwiper />
      <News />
      <ProductsSection />
      <Menyu />
      <Videos />
      <AllProducts />
      <WhyUs />
      <SupplyDemand />
      <Sertification />
      <Partner />
    </div>
  );
};

export default Home;
