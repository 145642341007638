import React from "react";
import { Link } from "react-router-dom";
import { API } from "../../services/imgAPI";
import "./NewsCard.scss";

function NewsCard({ img, title, subtitle, id }) {
  return (
    <div className="newsCard">
      {/* <Link to={`/news/info/${id}`}> */}
        <div className="newsCard__newsCardLeft">
          <div className="newsCard__newsCardLeft__newsPhoto">
            <img src={API + img} alt="" />
          </div>
          <div className="newsCard__newsCardLeft__newsText">
            <h3>{title}</h3>
            <p>{subtitle}</p>
          </div>
        </div>
      {/* </Link> */}
    </div>
  );
}

export default NewsCard;
