import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BigCardNew from "../../../../components/BigCardNew/BigCardNew";
import NewsCard from "../../../../components/NewsCard/NewsCard";
import { Language } from "../../../../services/lang";
import "./News.scss";

function News() {
  const { allNews } = useSelector((state) => state.allNews);
  const sortNews = allNews.filter((_, index) => index > 0 && index < 3);
  return (
    <div className="news globalContainer">
      <div className="news__title">
        {/* <Link to={"/news/all"}> */}
          <h2>{Language("NEWS")}</h2>
        {/* </Link> */}
      </div>

      <div className="news__newsTop">
        <BigCardNew data={allNews[0]} />
      </div>
      <div className="news__newsBottom">
        {sortNews.map((item) => (
          <NewsCard
            img={item.img}
            title={item.title}
            subtitle={item.subtitle}
            id={item.id}
            key={item.id}
          />
        ))}
      </div>
    </div>
  );
}

export default News;
// {lang === "uz"
// ? "So'ngi Yangiliklar"
// : lang === "ru"
// ? "Последние новости"
// : lang === "en"
// ? "Latest news"
// : null}
