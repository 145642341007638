import UZ from "./../language/uz.json";
import RU from "./../language/ru.json";
import EN from "./../language/en.json";
export function Language(key) {
  let lang = localStorage.getItem("language");
  let data = null;

  switch (lang) {
    case "uz":
      data = UZ;
      break;

    case "ru":
      data = RU;
      break;

    case "en":
      data = EN;
      break;

    default:
      break;
  }

  if (data?.hasOwnProperty(key)) {
    return data[key];
  }

  return key;
}
