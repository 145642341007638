import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import VideoCard from "../../../../components/VideoCard/VideoCard";
import { API } from "../../../../services/imgAPI";
import { Language } from "../../../../services/lang";
import "./Videos.scss";

function Videos() {
  const { allVideo } = useSelector((state) => state.allVideo);
  const data = allVideo.filter((_,index)=> index < 2)
  const lang = localStorage.getItem("language");
  return (
    <div className="videos globalContainer">
      <div className="videos__roomVideos ">
        <div className="videosTitle">
        <Link to={"/video/all"}>
            <h3>{Language("VIDEO")}</h3>
          </Link>
        </div>
        <div className="videos__roomVideos__videosCard">
          {data.map((item) => (
            <VideoCard
              img={API + item.img}
              title={item.title}
              video={item.link}
              id={item.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Videos;
