import React from "react";
import { useSelector } from "react-redux";
import { HiLocationMarker } from "react-icons/hi";
import { FaPhoneAlt } from "react-icons/fa";

import Dropdown from "../../Dropdown/Dropdown";
import { Language } from "../../../services/lang";
import { mask } from "../../../services/mask";
import "./index.scss";

const TopHeader = () => {
  const { allNumbers } = useSelector((state) => state.allNumbers);
  return (
    <nav className="topHeaderContainer">
      <section className="topHeader globalContainer">
        <div className="topHeader__location">
          <HiLocationMarker /> <p>{Language("TASHKENT")}</p>
        </div>
        <div className="topHeader__about">
          <div className="phone">
            <div>
              <FaPhoneAlt />
            </div>
            <a href={`tel: ${allNumbers[0]?.phoneNumber}`}>
              <p>{mask(allNumbers[0]?.phoneNumber)}</p>
            </a>
          </div>
          <Dropdown />
        </div>
      </section>
    </nav>
  );
};

export default TopHeader;
