import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "../../services/imgAPI";
import "./index.scss";
const Category = () => {
  const { category } = useSelector((state) => state.category);

  return (
    <section className="category">
      {category.map((item) => (
        <Link to={`/category/${item.type}`}>
          <div className="iconBox" key={item.id}>
            <div className="productName">
              <img src={API + item.icon} alt="" />
            </div>
            <p>{item.name}</p>
          </div>
        </Link>
      ))}
    </section>
  );
};

export default Category;
