import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { API } from "../../../services/imgAPI";
import { Language } from "../../../services/lang";
import "./index.scss";
const ProductCard = ({ img, type, name, id }) => {
  const lang = localStorage.getItem("language");
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const navigate = useNavigate();
  const ProductView = () => {
    navigate(`/product/view/${id}`);
  };
  return (
    <section className="product-card">
      <div className="product-card-img">
        <Slider {...settings} className="slider">
          {img?.map((item) => (
            <div className="slider-item">
              <img src={API + item} alt="" />
            </div>
          ))}
        </Slider>
      </div>
      <div className="product-card-body" onClick={ProductView}>
        <p>{name}</p>
        <h6>{Language("PRICE")}</h6>
        <h5>#{type}</h5>
      </div>
    </section>
  );
};

export default ProductCard;
