import React, { useEffect } from "react";
import "./NewsInfo.scss";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { API } from "../../../../../../services/imgAPI";

const NewsInfo = () => {
  const { allNews } = useSelector((state) => state.allNews);
  const params = useParams();
  const data = allNews.filter((item) => item.id === params.id);


  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <>
      <div className="newsInfo globalContainer">
        <div className="newsInfo__infoGroup">
          <div className="newsInfo__infoGroup__infoPhoto">
            <img src={API + data[0].img} alt="" />
          </div>
          <div className="newsInfo__infoGroup__infoText">
            <h3>{data[0].title}</h3>
            <p>{data[0].body}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsInfo;
