import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import BottomHeader from "../Header/BottomHeader/BottomHeader";
import TopHeader from "../Header/TopHeader/TopHeader";
import Loader from "../Loader/Loader";
const Layout = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const language = localStorage.getItem("language");

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    language === null && localStorage.setItem("language", "uz");
  }, [language]);

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <TopHeader />
          <BottomHeader />
          <div>{children}</div>
          <Footer />
        </>
      )}
    </>
  );
};

export default Layout;
