import React from "react";
import uzbFlag from "../../assets/icons/uzbFlag.svg";
import rusFlag from "../../assets/icons/rusFlag.svg";
import engFlag from "../../assets/icons/engFlag.svg";
import "./index.scss";

import { Dropdown as Drop, Menu, Space } from "antd";
const Dropdown = () => {
  const language = localStorage.getItem("language")
  const UZB = () => {
    localStorage.setItem("language", "uz");
    window.location.reload();
  };
  const RU = () => {
    localStorage.setItem("language", "ru");
    window.location.reload();
  };
  const EN = () => {
    localStorage.setItem("language", "en");
    window.location.reload();
  };
  const menu = (
    <Menu
      items={[
        {
          label: (
            <div
              className="flag"
              onClick={
                language === "uz"
                  ? RU
                  : language === "ru"
                  ? UZB
                  : language === "en"
                  ? RU
                  : null
              }
            >
              <p>
                {language === "uz"
                  ? "РУ"
                  : language === "ru"
                  ? "UZ"
                  : language === "en"
                  ? "РУ"
                  : null}
              </p>
              <img
                src={
                  language === "uz"
                    ? rusFlag
                    : language === "ru"
                    ? uzbFlag
                    : language === "en"
                    ? rusFlag
                    : null
                }
                alt=""
              />
            </div>
          ),
          key: "0",
        },
        {
          label: (
            <div
              className="flag"
              onClick={
                language === "uz"
                  ? EN
                  : language === "ru"
                  ? EN
                  : language === "en"
                  ? UZB
                  : null
              }
            >
              <p>
                {language === "uz"
                  ? "EN"
                  : language === "ru"
                  ? "EN"
                  : language === "en"
                  ? "UZ"
                  : null}
              </p>
              <img
                src={
                  language === "uz"
                    ? engFlag
                    : language === "ru"
                    ? engFlag
                    : language === "en"
                    ? uzbFlag
                    : null
                }
                alt=""
              />
            </div>
          ),
          key: "1",
        },
      ]}
    />
  );
  return (
    <Drop overlay={menu} trigger={["click"]} placement="bottomRight">
      <Space>
        <div className="flags">
          <p>
            {language === "uz"
              ? "UZ"
              : language === "ru"
              ? "РУ"
              : language === "en"
              ? "EN"
              : null}
          </p>
          <img
            src={
              language === "uz"
                ? uzbFlag
                : language === "ru"
                ? rusFlag
                : language === "en"
                ? engFlag
                : null
            }
            alt=""
          />
        </div>
      </Space>
    </Drop>
  );
};

export default Dropdown;
