import { NEWS_ERROR, NEWS_LOADING, NEWS_SUCCESS } from "./type";

const initialState = {
  allNews: [],
  loading: true,
  error: null,
};

export const allNews = (state = initialState, { type, payload }) => {
  switch (type) {
    case NEWS_LOADING:
      return { ...state, loading: true };
    case NEWS_SUCCESS:
      return { ...state, loading: false, allNews: payload };
    case NEWS_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
