import React from "react";
import Slider from "react-slick";
import sherin from "../../../../assets/partnier/sherin.png";
import savushkin from "../../../../assets/partnier/Savushkin.png";
import danone from "../../../../assets/partnier/danone.jpg";
import sagban from "../../../../assets/partnier/sagban.png";
import zulya from "../../../../assets/partnier/zulya.png";
import svalya from "../../../../assets/partnier/svalya.png";
import { Language } from "../../../../services/lang";
import "./Partner.scss";

let settings = {
  dots: false,
  infinite: true,
  arrows: false,
  autoplay: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: true,
  speed: 5000,
  autoplaySpeed: 0,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1370,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
  ],
};

function Partner() {
  return (
    <section className="globalContainer mt-20">
      <h3>{Language("PARTNERS")}</h3>
      <div className="my-5 marqueeContent">
        <div className="contentBox">
          <div className="contentItem">
            <div>
              <img src={sherin} className="select-none" alt="" />
            </div>
            <div>
              <img src={savushkin} alt="" />
            </div>
            <div>
              <img src={danone} alt="" />
            </div>
            <div>
              <img src={sagban} alt="" />
            </div>
            <div>
              <img src={zulya} alt="" />
            </div>
            <div>
              <img src={svalya} alt="" />
            </div>
          </div>
          <div className="contentItem">
            <div>
              <img src={sherin} className="select-none" alt="" />
            </div>
            <div>
              <img src={savushkin} alt="" />
            </div>
            <div>
              <img src={danone} alt="" />
            </div>
            <div>
              <img src={sagban} alt="" />
            </div>
            <div>
              <img src={zulya} alt="" />
            </div>
            <div>
              <img src={svalya} alt="" />
            </div>
          </div>
          <div className="contentItem">
            <div>
              <img src={sherin} className="select-none" alt="" />
            </div>
            <div>
              <img src={savushkin} alt="" />
            </div>
            <div>
              <img src={danone} alt="" />
            </div>
            <div>
              <img src={sagban} alt="" />
            </div>
            <div>
              <img src={zulya} alt="" />
            </div>
            <div>
              <img src={svalya} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partner;
