import { useDispatch } from "react-redux/es/exports";
import { actionCategory } from "../store/category/action";
import { actionMainSwiper } from "../store/mainSwiper/action";
import { actionAllNews } from "../store/news/action";
import { actionAllNumbers } from "../store/numbers/action";
import { actionAllProduct } from "../store/products/action";
import { actionVideo } from "../store/video/action";

function useRequestAPI() {
  const dispatch = useDispatch();
  const request = () => {
    dispatch(actionAllNumbers());
    dispatch(actionMainSwiper());
    dispatch(actionAllNews());
    dispatch(actionAllProduct());
    dispatch(actionCategory());
    dispatch(actionVideo());
  };

  return [request];
}

export { useRequestAPI };
