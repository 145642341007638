import { getNumbers } from "./api";
import { NUMBERS_ERROR, NUMBERS_LOADING, NUMBERS_SUCCESS } from "./type";

export const actionAllNumbers = () => async (dispatch) => {
  await dispatch({ type: NUMBERS_LOADING });
  try {
    const { data } = await getNumbers();
    await dispatch({ type: NUMBERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: NUMBERS_ERROR, payload: error });
  }
};
