import React from "react";
import "./WhyUs.scss";
import induv from "../../../../assets/whyUs/operator.png";
import skidka from "../../../../assets/whyUs/skidka.png";
import dastavka from "../../../../assets/whyUs/dastavka.png";
import sifat from "../../../../assets/whyUs/sifat.png";
import { Language } from "../../../../services/lang";

function WhyUs() {
  const lang = localStorage.getItem("language");
  return (
    <div className="whyUs">
      <div className="whyUs__whyContainer globalContainer">
        <h3>
        {Language("WHYUS")}
        </h3>
        <div className="whyUs__whyContainer__whySection">
          <div className="whyUs__whyContainer__whySection__SectionOne">
            <p> {Language("STUDY")} </p>
            
            <img src={induv} alt="...." />
          </div>
          <div className="whyUs__whyContainer__whySection__SectionTwo">
            <p>{Language("QUALITY")}</p>
            
            <img src={sifat} alt="...." />
          </div>
          <div className="whyUs__whyContainer__whySection__SectionThree">
            <p> {Language("DELIVERY")} </p>
            <img src={dastavka} alt="...." />
          </div>
          <div className="whyUs__whyContainer__whySection__SectionFour">
            <p>  {Language("DISCOUNTS")} </p>
            
            <img src={skidka} alt="...." />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;