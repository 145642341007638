import React from 'react'
import Category from '../../../../components/Category/Category'

import "./Menyu.scss"


function  Menyu() {
  return (
    <div className='menyu'>
        <div className='menyu__menyuRoom globalContainer'>
          <div className='menyu__menyuRoom__menyuCategory'></div>
       <Category/>
        </div>
    </div>
  )
}

export default  Menyu