import React, { useEffect } from "react";
import "./index.scss";
import { useParams } from "react-router-dom";
import { Image } from "antd";
import { useSelector } from "react-redux";
import { API } from "../../services/imgAPI";
import { useState } from "react";
const ProductView = () => {
  const { allProducts } = useSelector((state) => state.allProducts);
  const params = useParams();
  const allData = [];
  allProducts.map((item) => item.data.map((item) => allData.push(item)));
  const data = allData.filter((item) => item.id === params.id);
  console.log(data, "data");
  const [count, setCount] = useState(0);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <div className="productView globalContainer">
      <h6>{data[0].name}</h6>
      <div className="product-images">
        <div className="vertical-images">
          <img src={API + data[0].img[0]} alt="" onClick={() => setCount(0)} />
          <img src={API + data[0].img[1]} alt="" onClick={() => setCount(1)} />
          <img src={API + data[0].img[2]} alt="" onClick={() => setCount(2)} />
        </div>

        <div className="current-img">
          <img src={API + data[0].img[count]} alt="" />
        </div>
      </div>
      <p style={{ marginTop: "20px", fontSize: "20px" }}>{data[0]?.body}</p>
    </div>
  );
};

export default ProductView;
