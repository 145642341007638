import { VIDEO_ERROR, VIDEO_LOADING, VIDEO_SUCCESS } from "./type";

const initialState = {
    allVideo: [],
  loading: true,
  error: null,
};

export const allVideo = (state = initialState, { type, payload }) => {
  switch (type) {
    case VIDEO_LOADING:
      return { ...state, loading: true };
    case VIDEO_SUCCESS:
      return { ...state, loading: false, allVideo: payload };
    case VIDEO_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
