import { getNews } from "./api";
import { NEWS_ERROR, NEWS_LOADING, NEWS_SUCCESS } from "./type";

export const actionAllNews = () => async (dispatch) => {
  await dispatch({ type: NEWS_LOADING });
  try {
    const { data } = await getNews();
    await dispatch({ type: NEWS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: NEWS_ERROR, payload: error });
  }
};
