import React from "react";
import { Image } from "antd";
import { Language } from "../../services/lang";
import "./Sertification.scss";

const Sertification = () => {
  const sit1 = require("../../assets/images/sertification1.jpg")
  const sit2 = require("../../assets/images/sertification2.jpg")
  const sit3 = require("../../assets/images/sertification3.jpg")
  const sit4 = require("../../assets/images/sertification4.jpg")
  return (
    <div className="sliderWrapper">
      <div className="sliderContainer globalContainer">
        <div className="sliderContainer__sliderText">
          <h3> {Language("SERTIFICAT")}</h3>
        </div>
        <div className="sliderContainer__sliderSertificat">
          <Image src={sit1} />
          <Image src={sit2} />
          <Image src={sit3} />
          <Image src={sit4} />
        </div>
      </div>
    </div>
  );
};

export default Sertification;
