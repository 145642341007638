import React from "react";
import log from "../../assets/icons/logo.webp.svg";
import { GrInstagram } from "react-icons/gr";
import { BsFacebook } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";
import { IoLogoYoutube } from "react-icons/io";
import { HiLocationMarker } from "react-icons/hi";
import { Link } from "react-router-dom";
import { Language } from "../../services/lang";
import "./index.scss";
const Footer = () => {
  return (
    <>
      <div className="footer ">
        <div className="footer__footerDiv globalContainer">
          <div className="footer__footerDiv__footerLeft">
            <img src={log} alt="Logo" />
            <p>{Language("QAZI")}</p>
            <div className="footer__footerDiv__footerLeft__footerIt">
              <a href="https://instagram.com/qazimarkazi?igshid=YmMyMTA2M2Y=">
                <GrInstagram />
              </a>
              <a href="https://www.youtube.com/@qazimarkazi">
                <IoLogoYoutube />
              </a>
              <a href="/">
                <BsTelegram />
              </a>
              <a href="/">
                <BsFacebook />
              </a>
            </div>
          </div>
          <div className="footer__footerDiv__footerGroup">
            <div className="footer__footerDiv__footerGroup__footerOne">
              <h3> {Language("ABOUTME")}</h3>
              <ul>
                <Link to={`/products`}>
                  <li>{Language("OURPRADUCT")}</li>
                </Link>
                <Link to={`/aboutUs`}>
                  <li>{Language("CENTERINFO")}</li>
                </Link>
                <Link to={`/news/all`}>
                  <li> {Language("ALLNEWS")}</li>
                </Link>
                <Link to={`/video/all`}>
                  <li>{Language("VIDEO")}</li>
                </Link>
              </ul>
            </div>
            <div className="footer__footerDiv__footerGroup__footerTwo">
              <h3>{Language("ADRESS")}</h3>
              <ul>
                <li>
                  <HiLocationMarker />
                  {Language("TASHKENT")}
                </li>
                <li>
                  <HiLocationMarker />
                  Qibray
                </li>
                <li>
                  <HiLocationMarker />
                  {Language("ANDIJAN")}
                </li>
                <li>
                  <HiLocationMarker />
                  {Language("SAMARQAND")}
                </li>
              </ul>
            </div>
            <div className="footer__footerDiv__footerGroup__footerThree">
              <h3>
                {Language("WORKING")}
              </h3>
              <ul>
                <li>
                  {Language("WORKINTIME")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
