import { NUMBERS_ERROR, NUMBERS_LOADING, NUMBERS_SUCCESS } from "./type";

const initialState = {
  allNumbers: [],
  loading: true,
  error: null,
};

export const allNumbers = (state = initialState, { type, payload }) => {
  switch (type) {
    case NUMBERS_LOADING:
      return { ...state, loading: true };
    case NUMBERS_SUCCESS:
      return { ...state, loading: false, allNumbers: payload };
    case NUMBERS_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
