import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import { useRequestAPI } from "./hooks/useRequestAllAPI";
import routes from "./routes";

function App() {
  const [request] = useRequestAPI();

  useEffect(() => {
    request();
  }, []);
  return (
    <Layout>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route path={path} element={element} key={path} />
        ))}
      </Routes>
    </Layout>
  );
}

export default App;
