import React from "react";
import "./index.scss";
const Loader = () => {
  return (
    <div className="spinner-wrap">
    <div class="spinner">
    <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
  </div>
    </div>
  );
};

export default Loader;
