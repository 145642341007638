import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import ProductCard from "../../components/Cards/ProductCard/ProductCard"
import { API } from "../../services/imgAPI";
import "./index.scss"
const Products = () => {
  const { allProducts } = useSelector((state) => state.allProducts);
  const allData = [];
  allProducts.map((item) => item.data.map((item) => allData.push(item)));
  const func = (param) => {
    const data = allProducts.filter((item) => item.id === param);
    return data[0].name;
  };
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <div className='allProductsContainer globalContainer'>
      <h3>Barcha mahsulotlar</h3>
      <div className='allProductsTitle'>
      {allData.map((item) => (
            <ProductCard
              img={API + item.img}
              name={item.name}
              type={func(item.type_id)}
              id={item.id}
              key={item.id}
            />
          ))}
      </div>
    </div>
  )
}

export default Products